import * as React from "react"
import SEO from "../../components/seo";
import LayoutRechtsgebiete from "../../components/LayoutRechtsgebiete";


export default () => {

    return (
        <LayoutRechtsgebiete>
            <div id="content" className="animate-fade-in">
                <h1>Inkasso</h1>
                <p>
                    Sie sind Unternehmer und stellen Ihre Dienste Ihren Kunden in Rechnung? Sicherlich ist Ihnen dann
                    der
                    Mehraufwand bekannt, der entsteht, wenn Ihre Kunden auch auf Mahnungen keine Zahlungen leisten. Umso
                    ärgerlicher wird es, wenn durch die schlechte Zahlungsmoral Ihrer Kunden die eigene Liquidität in
                    Gefahr
                    gerät. Vor allem bei kleineren Unternehmen oder Privatpersonen kann dies schnell zu einer
                    unverschuldeten Insolvenz führen. Doch soweit muss es nicht kommen.
                    Je schneller versucht wird Ihre Forderung gegenüber dem Kunden einzutreiben, umso höher ist die
                    Chance
                    noch an Ihr Geld zu kommen. Wenn Sie versuchen die offene Forderung selbst beizutreiben, kann dies
                    oft
                    zu einem hohen Zeit- und Kostenaufwand führen. Möglicherweise fehlt es auch an dem benötigten
                    Fachwissen
                    Ihrer Mitarbeiter und spätestens, wenn der Kunde z. B. Widerspruch gegen den von Ihnen beantragten
                    Mahnbescheid erhebt, wird die anwaltliche Hilfe unentbehrlich.
                </p>
                <p>
                    Als Rechtsanwaltskanzlei arbeite ich ebenso effektiv wie namhafte Inkassobüros. Der Vorteil
                    gegenüber
                    einem reinen Inkassobüro besteht u. a. darin, dass ich bei der Beauftragung erst einmal prüfe, ob
                    Ihre
                    Forderung überhaupt durchsetzbar ist. Auch senken Sie bei direkter Beauftragung durch mich das
                    Risiko
                    der Nichtübernahme der zu entstehenden möglichen Kosten. Mein Ziel ist es Ihnen die zeitaufwändige
                    Mehrarbeit für den Forderungseinzug ganz abzunehmen. Ich werde bei Mandatsbeauftragung das
                    gewünschte
                    Vorgehen genauestens mit Ihnen besprechen, z. B. ob vorab ein außergerichtliches
                    Aufforderungsschreiben
                    gewünscht ist oder ob das gerichtliche Mahnverfahren eingeleitet werden soll. Zudem prüfe ich für
                    Sie,
                    ob es nicht sinnvoller ist, direkt das Klageverfahren gegen Ihren säumigen Kunden einzuleiten.
                    Sobald ich von Ihnen beauftragt bin, werden Sie von jeder durch mich eingeleiteten Maßnahme
                    informiert,
                    sodass Sie sich um nichts mehr kümmern müssen.
                </p>
                <p>
                    Als letztes bleibt noch festzuhalten, dass das von mir betriebene Inkasso nicht teurer ist, wie die
                    Beauftragung eines reinen Inkassobüros. Auch trägt im Erfolgsfalle der Schuldner meine Kosten,
                    vorausgesetzt, dass sich dieser im Verzug befunden hat. Sicherlich werde ich auch hier eine adäquate
                    Lösung für Sie finden.
                </p>
            </div>
        </LayoutRechtsgebiete>
    )
};

export const Head = () => {
    return (<SEO title={'Inkasso'}/>);
}
